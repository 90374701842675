import React from 'react';
import CookieLayout from '../components/CookieLayout';
import SEO from '../components/Seo';
import LandingBanner from '../components/LandingBanner/LandingBanner';

const WhisteblowerPage = () => {
  return (
    <CookieLayout>
      <SEO title="Whistleblowing Policy" />
      <LandingBanner title="Whistleblowing Policy" alt />
      <section className="internal-page__section">
        <h2>1. Introduction </h2>
        <p>
          W&W Asset Management Ireland DAC (“WWAMI” or the “Firm”) is committed to the principle of
          accountability and to developing a culture where it is safe and acceptable for all its
          employees to raise their concerns about what is happening at work if they have a
          reasonable belief of wrongdoing. Employees have an important role in identifying and
          reporting concerns about wrongdoing which has come to their attention in connection with
          their employment.
        </p>
        <p>
          The Firm will investigate genuine and reasonable concerns expressed by its staff relating
          to perceived wrongdoing in the Firm and will ensure that employees are not discriminated
          against or suffer detriment as a result of raising a concern.
        </p>
        <p>
          For the purposes of the policy, the term ‘employee’ or ‘staff’ includes former employees
          of the Firm and also includes individuals who currently work or have worked within the
          Firm such as external consultants, contractors and agency personnel.
        </p>
        <h2>2. Objective</h2>
        <p>
          This Policy lays down the procedure for staff to follow if they have concerns about any
          wrongdoing connected with the Firm, without fear of adverse repercussions to the
          individual. Certain disclosures made under this policy will constitute ‘protected
          disclosures’ for the purposes of the Protected Disclosures Act 2014 and the Central Bank
          (Supervision and Enforcement) Act 2013. (“the Acts”).
        </p>
        <p>
          The Protected Disclosures Amendment Act 2022 strengthens and extends existing
          whistleblowing safeguards, and commenced on 1 January 2023. The firm is required to comply
          with provisions of the act.
        </p>
        <p>
          Under the Protected Disclosures Act, you make a protected disclosure if you are a
          worker/volunteer/shareholder, and you disclose relevant information in a particular way.
          Information is relevant if it came to your attention in connection with your work and you
          reasonably believe that it tends to show wrongdoing.
        </p>
        <p>
          The act provides robust statutory protections in relation to such disclosures. However,
          where persons provide anonymous reports other than “workers”, they fall under the Central
          Bank (Supervision and Enforcement) Act 2013. In those circumstances, an anonymous report
          is not a “protected disclosure” due to the provisions of the 2013 Act. The Policy aims to:
        </p>
        <ol type="a">
          <li>
            Encourage employees/shareholders/volunteers to feel confident in raising concerns and to
            question and act upon such concerns.
          </li>
          <li>Provide avenues for employees to raise those concerns and receive feedback.</li>
          <li>Ensure that employees receive a response to their concerns; and </li>
          <li>
            Reassure employees that they will be protected from reprisals, subsequent
            discrimination, victimisation, or disadvantage if they had a reasonable belief that they
            made the disclosure in good faith.
          </li>
        </ol>
        <h2>3. Scope</h2>
        <p>
          This policy is intended to provide a safe and secure mechanism for employees who may wish
          to report any reasonable concerns that they may have about perceived wrongdoing (including
          suspected fraud) in, or connected with, the Firm.
        </p>
        <h2>4. Wrongdoings</h2>
        <p>
          The following matters are examples of ‘wrongdoings’ for the purposes of the Whistleblowing
          Policy:
        </p>
        <ol type="a">
          <li>That an offence has been, is being or is likely to be committed.</li>
          <li>That a person is failing to comply with a legal obligation.</li>
          <li>
            That a staff member has breached or is continuing to breach the Code of Conduct
            contained in the Staff Handbook (the “Code”) or the staff rules referenced by the Code.
          </li>
          <li>That a miscarriage of justice has occurred.</li>
          <li>That the health and safety of an individual is being endangered.</li>
          <li>That the environment is being damaged.</li>
          <li>That an unlawful or improper use of public funds has occurred.</li>
          <li>
            That an act or omission on behalf of a public body (including the Central Bank) is
            oppressive, discriminatory, grossly negligent or constitutes gross mismanagement.
          </li>
          <li>
            That information tending to show any matter relating to the above is / was concealed or
            destroyed. This list is not exhaustive; in addition to the matters listed (above), there
            will be many other areas of concern which may be raised in line with this policy.
          </li>
        </ol>
        <p>
          Any concern raised by an employee will be treated seriously, assessed on its merits and
          appropriately investigated. All efforts will be made to give feedback to the individual on
          the outcome of any investigation, subject to legal or investigatory limits. This process
          will be agreed on a case-by-case basis as issues such as confidentiality may need to be
          taken into account.
        </p>
        <p>
          The Whistleblowing Policy does not apply to raising grievances about an employee’s
          personal situation and, thus, does not replace existing procedures for personal
          differences or conflicts.
        </p>
        <h2>5. Employees who make a confidential disclosure</h2>
        <p>
          The Firm will not tolerate any harassment or victimisation (including informal pressures)
          of a discloser and will take appropriate action to protect the discloser when they raise a
          concern in good faith.
        </p>
        <p>
          Any evidence that an employee created difficulties for the discloser due to them raising a
          genuine concern, or, that an attempt has been made to cover up wrongdoing will be treated
          very seriously.
        </p>
        <p>
          Under the Protected Disclosures (Amendment) Act 2022, considers this wrongdoing a criminal
          offence, and anyone convicted of this wrongdoing faces fines of up to €250,000 or to
          imprisonment for up to 2 years or both.
        </p>
        <p>
          In criminal cases, if an employee brings a case to the courts, of penalisation due to
          making a protected disclosure, the presumption will be that the alleged act of
          victimisation or harassment has occurred, unless the employer can prove otherwise.
        </p>
        <p>
          If an employee makes an allegation in good faith, but the allegation is not confirmed by
          an investigation, no action will be taken against them. It is important that all employees
          understand that it is acceptable for them to raise a concern even in cases where a genuine
          concern turns out to be mistaken or misguided. If, however, an employee raises a matter
          frivolously, maliciously (i.e., the employee knows the matter to be untrue) or for
          personal gain, disciplinary action may be taken against them.
        </p>

        <h2>6. How to Raise a Concern</h2>
        <p>
          An employee who is concerned about a perceived wrongdoing should raise their concern in
          the first instance with the Managing Director, Compliance or the Head of the W&W Group
          Compliance. The earlier a concern is raised, the easier it is to take action.
        </p>
        <p>
          Former employees of the Firm should raise a concern about perceived wrongdoing to
          Compliance, W&W Group Compliance or Managing Director.
        </p>
        <p>
          In certain cases, it may be more appropriate to raise concerns with the Firm’s Chairman or
          the W&W Group Compliance Function via the group compliance whistleblowing mailbox
          (hinweis@ww-ag.com)
        </p>
        <p>
          Employees can also submit anonymous disclosures to the Central Bank relating to breaches
          of financial services legislation by their employer and such disclosures can be treated as
          a protected disclosure under the 2014 Act.
        </p>
        <p>
          Concerns may be raised verbally or in writing. Where a concern is raised verbally the
          following steps are to be taken by the employee raising the concern to ensure that the
          concern raised is acknowledged by the recipient as received in the manner intended by the
          employee.
        </p>
        <p>
          These steps are to ensure that the recipient is clear that what is intended as a
          disclosure is not construed by the recipient as a passing or casual comment:
        </p>
        <ol>
          <li>
            The employee raising the concern sends a written communication to the recipient. The
            written communication confirms:
          </li>
          <ul>
            <li>
              the fact that a disclosure was made (details of the disclosure need not be included,
              just the fact that a disclosure has been made);
            </li>
            <li>that a written acknowledgement from the recipient to the employee is required.</li>
          </ul>
          <li>
            The recipient responds with a written communication acknowledging receipt of the
            disclosure. For the purposes of passing the information received to those responsible
            for investigating the concerns, the recipient of the concern may record the employee’s
            concern in writing and should seek that the employee raising the concerns confirm that
            the details are accurately recorded.
          </li>
          <li>
            Once a report of suspected wrongdoing is made to a recipient that person should pass
            that information promptly to the Managing Director/ Compliance Function / Chairman as
            appropriate who will:
          </li>
          <ul>
            <li>Record and acknowledge the concern</li>
            <li>Make an assessment as to further action, including informing the Board.</li>
            <li>Refer the matter for investigation if appropriate.</li>
            <li>
              Inform the individual who raised the concern of the outcome of any investigation, if
              appropriate.
            </li>
          </ul>
        </ol>
        <p>
          Employees should note that the disclosure of confidential information externally may
          result in disciplinary action.
        </p>

        <h2>7. Confidentiality</h2>
        <p>
          All employees involved in the investigation will be required to maintain confidentiality
          in relation to the concerns being raised. Where an employee raising a concern has asked
          the Firm not to disclose their identity, the Firm will not do so unless required by law.
          Employees should be aware that there may be times when the Firm is unable to resolve a
          concern without revealing the employee’s identity; for example, where the employee’s
          personal evidence is essential.
        </p>
        <p>
          If so, it will be discussed with the employee whether and how the matter can best proceed.
          It should be noted that where an employee seeks anonymity, it is much more difficult to
          fully investigate their concerns, and the Firm will not be in a position to provide
          feedback to them.
        </p>

        <h2>8. Review of this Policy</h2>
        <p>
          The Whistleblowing Policy will be reviewed on an annual basis by Compliance and presented
          to the Board for approval, if any material changes arise.
        </p>
        <p>
          <u>This policy was last updated in Q3 2023.</u>
        </p>
      </section>
    </CookieLayout>
  );
};

export default WhisteblowerPage;
