import React from 'react';
import PropTypes from 'prop-types';
import Navigation from './Navigation/Navigation';
import Footer from './Footer/Footer';
import '../styles/styles.scss';

const CookieLayout = ({ children }) => {
  return (
    <>
      <Navigation />
      <main className="main-content">{children}</main>
      <Footer />
    </>
  );
};

CookieLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CookieLayout;
